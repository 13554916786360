import React, { Component } from 'react';
import '../App.css';
import footPrint2 from '../assets/footprint3.png';
import '../components/footePrint';
import FootPrint from '../components/footePrint';
import $ from "jquery";
import '../newRoadmap.css'
 
$(window).on('scroll', function () {
    function isScrollIntoView(elem, index) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();
        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(window).height() * .5;
        if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
            $(elem).addClass('active');
        }
        if (!(elemBottom <= docViewBottom)) {
            $(elem).removeClass('active');
        }
    }
    var timeline = $('.vertical-scrollable-timeline li');
    Array.from(timeline).forEach(isScrollIntoView);
});

class Roadmap extends Component {


    render() {
        return (

            <div class="boxWrap2">

                <div class="storyH">ROADMAP</div>
                <FootPrint />

                <div class="timeline-container">
                    <ul class="vertical-scrollable-timeline">
                        <li>
                            <h2>Phase 1</h2>
                            <p>Make a profit so we can donate to charities and get some presents for our moms.</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />

                            </div>
                        </li>
                        <li>
                            <h2>Phase 2</h2>
                            <p>Make 10000 more nfts</p>
                            <div class="icon-holder">
                            <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>Phase 3</h2>
                            <p>Launch our token</p>
                            <div class="icon-holder">
                            <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>

                        <li>
                            <h2>Phase 4:</h2>
                            <div class="rmH2">Once the money goal is reached launch our perks which will include:</div>
                            <p></p>
                            <p>Discounts in our Hotels (once construction is completed)</p>
                            <p>Lounges around the world with bars and rooms and showers (once construction is completed)</p>
                            <p>Invites to parties and discos around the world</p>
                            <p>Investment Opportunities offered to you exclusively</p>
                            <p>Invites to limited projects and huge giveaways</p>
                            <div class="icon-holder">
                            <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                    </ul>
                </div>


                <FootPrint />

            </div>


        );

    }
}

export default Roadmap;