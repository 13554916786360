import './App.css';
import React, { Component } from 'react';
import Story from "./components/story";
import { SocialIcon } from 'react-social-icons';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Roadmap from './components/roadmap';
import os from './assets/opensea.png';
import Team from './components/team';
import FAQ from './components/faq';
import Carousel from './components/carousel';
import Footer from './components/footer';
import logo from './assets/logo.png';
import discord from './assets/discord.png';
import './drippy.css';
import nft from './assets/2.jpg';
import picSpecial from './assets/208.png';

var Scroll = require('react-scroll');

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;


const opensea = () => {
	window.open("https://opensea.io/collection/pooh-nfts");
}


let account;
let mintAmount = 1;
let special_mintAmount = 1;
let valueOfNFTs = 0;
let valueOfNFTsSpecial = '1260000000000000000';
let totalSupplyNFT;
let totalSupplyNFT2;
let maxMintNFTs;
let onlyLeft;
let owner;
let publicSale;
let user_input;
let wMintAmount;
let myTokens = [];
let myTokens2 = [];
let adminWhitelistAddresses = [];
let adminPanel = 0;
let adminWhitelistAddresses2 = [];
let maxTokensToBuy = "";
let testingvalue;
let wlMint;
let FinalResult;
let wlMsg = "Whitelist Mint";
let public_sale_cost;
let presalesale_cost;
let MAX_PER_WALLET;
let public_mint_status = false;
let presale_status = false;
let specialMintAmount = "";
let specialPrice = "";
let specialTotalSupply;
let normalNFTSupply;
let nftSpecialMintingAmount = "1";
let specialMintActive = false;
let normalMintActive = false;

// 1. Import libraries. Use `npm` package manager to install
const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

// 2. Collect list of wallet addresses from competition, raffle, etc.
// Store list of addresses in some data sheeet (Google Sheets or Excel)
let whitelistAddresses = [

];

let whitelistAddresses2 = [];

// 3. Create a new array of `leafNodes` by hashing all indexes of the `whitelistAddresses`
// using `keccak256`. Then creates a Merkle Tree object using keccak256 as the algorithm.
//
// The leaves, merkleTree, and rootHas are all PRE-DETERMINED prior to whitelist claim
const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

const leafNodes2 = whitelistAddresses2.map(addr2 => keccak256(addr2));
const merkleTree2 = new MerkleTree(leafNodes2, keccak256, { sortPairs: true });

// 4. Get root hash of the `merkleeTree` in hexadecimal format (0x)
// Print out the Entire Merkle Tree.
const rootHash = merkleTree.getRoot();
const rootHashHash = merkleTree.getHexRoot();

const rootHash2 = merkleTree2.getRoot();
const rootHashHash2 = merkleTree2.getHexRoot();

const ABI = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_name",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_symbol",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "OperatorNotAllowed",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "_ukranianTokenIDS",
				"type": "uint256[]"
			}
		],
		"name": "dataInput",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "deleteData",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_mintAmount",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_mintAmount",
				"type": "uint256"
			}
		],
		"name": "mintSpecial",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_state",
				"type": "bool"
			}
		],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseExtension",
				"type": "string"
			}
		],
		"name": "setBaseExtension",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"name": "setContractURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setMAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_public_mint_status",
				"type": "bool"
			}
		],
		"name": "setPublic_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_royaltyAddress",
				"type": "address"
			}
		],
		"name": "setRoyaltyAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint96",
				"name": "_royaltyFeesInBips",
				"type": "uint96"
			}
		],
		"name": "setRoyaltyInfo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_specialPrice",
				"type": "uint256"
			}
		],
		"name": "setSpecialPrice",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "baseExtension",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "baseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "calculateRoyalty",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "OPERATOR_FILTER_REGISTRY",
		"outputs": [
			{
				"internalType": "contract IOperatorFilterRegistry",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "public_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "specialList",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "specialPrice",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "specialTotalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const address = "0x172eBf7dF86f6F1E2AA7cC46a8968C4408ee2b70";
let contract;


class App extends Component {

	state = {
		_nftSpecialMintingAmount: "1",
		walletAddress: "",
		totalSupply: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusErrorA: false,
		statusErrorB: false,
		statusLoadingA: false,
		statusLoadingB: false,
		successA: false,
		successB: false,
		nftMintingAmount: '1',
		totalValue: '',
		specialTotalValue: '',
		presaleValue: "0",
		maxmint: '',
		_adminPanel: 0,
		_adminWhitelistAddresses: [],
		_adminWhitelistAddresses2: [],
		_maxTokensToBuy: "",
		_testingValue: '',
		_wlMint: '',
		_FinalResult: '',
		_wlMsg: 'Whitelist Mint',
		_public_sale_cost: '77000000000000000',
		_presalesale_cost: '',
		_MAX_PER_WALLET: '',
		_public_mint_status: '',
		_presale_status: '',
		_cost: '',
		_mintStatus: false,
		_specialMintAmount: '',
		_specialPrice: '380000000000000000',
		_specialTotalSupply: '',
		_normalNFTSupply: '',
		_normalMintActive: false,
		_specialMintActive: false

	}

	onSubmitMinus = async event => {
		event.preventDefault();

		mintAmount = mintAmount - 1;

		if (mintAmount < 1) {
			mintAmount = 1
		}


		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 1000 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;
			wMintAmount = mintAmount;

			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {


			if (totalSupplyNFT < 1000) {

				onlyLeft = MAX_PER_WALLET - publicSale;

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;


				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			}
		}
	}

	onSubmitPlus = async event => {
		event.preventDefault();

		//,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
		mintAmount = mintAmount + 1;

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 1000 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;
			wMintAmount = mintAmount;


			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {

			if (totalSupplyNFT < 1000) {

				onlyLeft = MAX_PER_WALLET - publicSale;
				console.log(onlyLeft);

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;

				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			}
		}
	}

	onSubmitMinusSpecial = async event => {
		event.preventDefault();

		special_mintAmount = special_mintAmount - 1;

		if (special_mintAmount < 1) {
			special_mintAmount = 1
		}


		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 20 - specialTotalSupply;

			if (special_mintAmount > onlyLeft) {
				special_mintAmount = onlyLeft;
			}

			valueOfNFTsSpecial = special_mintAmount * 0;
			wMintAmount = special_mintAmount;

			this.setState({ _nftSpecialMintingAmount: special_mintAmount });

			this.setState({ specialTotalValue: valueOfNFTsSpecial });
		} else {


			if (totalSupplyNFT < 20) {

				onlyLeft = MAX_PER_WALLET - publicSale;
				//.........//

				let onlyLeftSpecial = 20 - specialTotalSupply;
				if (onlyLeft < onlyLeftSpecial) {

					if (special_mintAmount > onlyLeft) {
						special_mintAmount = onlyLeft;
					}
				} else {
					if (special_mintAmount > onlyLeftSpecial) {
						special_mintAmount = onlyLeftSpecial;
					}
				}

				//..............//

				valueOfNFTsSpecial = special_mintAmount * this.state._specialPrice;
				wMintAmount = special_mintAmount;


				this.setState({ _nftSpecialMintingAmount: special_mintAmount });

				this.setState({ specialTotalValue: valueOfNFTsSpecial });



			}
		}
	}

	onSubmitPlusSpecial = async event => {
		event.preventDefault();

		//,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
		special_mintAmount = special_mintAmount + 1;

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 20 - specialTotalSupply;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTsSpecial = special_mintAmount * 0;
			wMintAmount = special_mintAmount;


			this.setState({ _nftSpecialMintingAmount: special_mintAmount });

			this.setState({ specialTotalValue: valueOfNFTsSpecial });
		} else {

			if (totalSupplyNFT < 20) {

				onlyLeft = MAX_PER_WALLET - publicSale;
				//.........//

				let onlyLeftSpecial = 20 - specialTotalSupply;
				if (onlyLeft < onlyLeftSpecial) {

					if (special_mintAmount > onlyLeft) {
						special_mintAmount = onlyLeft;
					}
				} else {
					if (special_mintAmount > onlyLeftSpecial) {
						special_mintAmount = onlyLeftSpecial;
					}
				}

				//..............//

				valueOfNFTsSpecial = special_mintAmount * this.state._specialPrice;
				wMintAmount = special_mintAmount;


				this.setState({ _nftSpecialMintingAmount: special_mintAmount });

				this.setState({ specialTotalValue: valueOfNFTsSpecial });



			}
		}
	}

	onSubmit2 = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		try {
			let owner = await contract.methods.owner().call();


			if (account != owner) {


				try {

					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);


					specialMintActive = false;
					normalMintActive = true;

					if (normalMintActive == true) {
						this.setState({ statusErrorA: false, statusLoadingA: true });
					} else {
						this.setState({ statusErrorA: false, statusLoadingA: false });
					}

					await contract.methods.mint(mintAmount * 1).send({ gasLimit: 385000, from: account, value: this.state.totalValue * 1 });

					if (normalMintActive == true) {
						this.setState({ statusLoadingA: false, successA: true });
					} else {
						this.setState({ statusLoadingA: false, successA: false });
					}


					console.log("Mint Amount :" + this.state.mintAmount);

				} catch (err) {
					this.setState({ errorMassageA: "ERROR : " + err.message, statusLoadingA: false, successA: false, statusErrorA: true });
					console.log(err);


				}
			} else {

				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);

					if (normalMintActive == true) {
						this.setState({ statusErrorA: false, statusLoadingA: true });
					} else {
						this.setState({ statusErrorA: false, statusLoadingA: false });
					}

					await contract.methods.mint(mintAmount * 1).send({ gasLimit: 385000, from: account, value: this.state.totalValue * 0 });

					if (normalMintActive == true) {
						this.setState({ statusLoadingA: false, successA: true });
					} else {
						this.setState({ statusLoadingA: false, successA: false });
					}

				} catch (err) {
					this.setState({ errorMassageA: "ERROR : " + err.message, statusLoadingA: false, successA: false, statusErrorA: true });
					console.log(err);


				}






			}
		} catch (err) {

			console.log(err);

		}
	}

	specialMint = async event => {
		event.preventDefault();


		try {

			let owner = await contract.methods.owner().call();


			if (account != owner) {


				try {

					console.log("specialMintAmount : " + this.state._specialPrice);

					let specialFullPrice = special_mintAmount * specialPrice;

					specialMintActive = true;
					normalMintActive = false;

					if (specialMintActive == true) {
						this.setState({ statusErrorB: false, statusLoadingB: true });
					} else {
						this.setState({ statusErrorB: false, statusLoadingB: false });
					}

					await contract.methods.mintSpecial(special_mintAmount * 1).send({ gasLimit: 385000, from: account, value: specialFullPrice * 1 });
					if (specialMintActive == true) {
						this.setState({ statusLoadingB: false, successB: true });
					} else {
						this.setState({ statusLoadingB: false, successB: false });
					}


				} catch (err) {
					this.setState({ errorMassageB: "ERROR : " + err.message, statusLoadingB: false, successB: false, statusErrorB: true });
					console.log(err);


				}
			} else {

				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTsSpecial:" + valueOfNFTsSpecial);
					console.log("specialMintAmount : " + specialMintAmount);

					if (specialMintActive == true) {
						this.setState({ statusErrorB: false, statusLoadingB: true });
					} else {
						this.setState({ statusErrorB: false, statusLoadingB: false });
					}

					await contract.methods.mintSpecial(special_mintAmount * 1).send({ gasLimit: 385000, from: account, value: 0 });

					if (specialMintActive == true) {
						this.setState({ statusLoadingB: false, successB: true });
					} else {
						this.setState({ statusLoadingB: false, successB: false });
					}

				} catch (err) {
					console.log(err);


				}






			}
		} catch (err) {

			console.log(err);

		}
	}

	walletConnect = async event => {
		event.preventDefault();

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });

			contract = new web3.eth.Contract(ABI, address);
			console.log("contract :" + contract);

			if (provider) {


				(async () => {


					if (web3Modal.cachedProvider != "walletconnect") {

						const chainId = 1;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/
											},
										],
									});
								}
							}
						}





						try {

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							specialTotalSupply = await contract.methods.specialTotalSupply().call();
							this.setState({ _specialTotalSupply: specialTotalSupply });
							console.log("specialTotalSupply:" + specialTotalSupply);

							normalNFTSupply = totalSupplyNFT - specialTotalSupply;
							this.setState({ _normalNFTSupply: normalNFTSupply });
							console.log("normalNFTSupply:" + normalNFTSupply);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							public_sale_cost = await contract.methods.publicSaleCost().call();
							this.setState({ _public_sale_cost: public_sale_cost });
							console.log("public_sale_cost :" + public_sale_cost);
							this.setState({ _cost: public_sale_cost });
							this.setState({ _mintStatus: false });

							MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
							this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
							console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

							public_mint_status = await contract.methods.public_mint_status().call();
							this.setState({ _public_mint_status: public_mint_status });
							console.log("public_mint_status :" + public_mint_status);

							specialPrice = await contract.methods.specialPrice().call();
							this.setState({ _specialPrice: specialPrice });
							console.log("specialPrice :" + specialPrice);

							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);
							//..........................................................................//
							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 1000 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;
								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 1000) {

									onlyLeft = 1000 - totalSupplyNFT;
									mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = MAX_PER_WALLET - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									//mintAmount = onlyLeft;

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}
							///................................///

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 1000 - totalSupplyNFT;

								if (special_mintAmount > onlyLeft) {
									special_mintAmount = onlyLeft;
								}

								valueOfNFTsSpecial = special_mintAmount * 0;
								wMintAmount = special_mintAmount;


								this.setState({ _nftSpecialMintingAmount: special_mintAmount });

								this.setState({ specialTotalValue: valueOfNFTsSpecial });
							} else {
								special_mintAmount = 1;

								if (totalSupplyNFT == 1000) {

									onlyLeft = 1000 - totalSupplyNFT;
									special_mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {
									special_mintAmount = 1;
									onlyLeft = MAX_PER_WALLET - publicSale;
									let onlyLeftSpecial = 20 - specialTotalSupply;
									if (onlyLeft < onlyLeftSpecial) {

										if (special_mintAmount > onlyLeft) {
											special_mintAmount = onlyLeft;
										}
									} else {
										if (special_mintAmount > onlyLeftSpecial) {
											special_mintAmount = onlyLeftSpecial;
										}
									}
									//mintAmount = onlyLeft;

									valueOfNFTsSpecial = special_mintAmount * this.state._specialPrice;
									wMintAmount = special_mintAmount;


									this.setState({ _nftSpecialMintingAmount: special_mintAmount });

									this.setState({ specialTotalValue: valueOfNFTsSpecial });
								}
							}


							//..........................................................................//

						} catch (err) {
							console.log("err: " + err);

						}
					} else {

						const chainId = 1;

						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/
											},
										],
									});
								}
							}
						}




						try {

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							specialTotalSupply = await contract.methods.specialTotalSupply().call();
							this.setState({ _specialTotalSupply: specialTotalSupply });
							console.log("specialTotalSupply:" + specialTotalSupply);

							normalNFTSupply = totalSupplyNFT - specialTotalSupply;
							this.setState({ _normalNFTSupply: normalNFTSupply });
							console.log("normalNFTSupply:" + normalNFTSupply);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							public_sale_cost = await contract.methods.publicSaleCost().call();
							this.setState({ _public_sale_cost: public_sale_cost });
							console.log("public_sale_cost :" + public_sale_cost);
							this.setState({ _cost: public_sale_cost });
							this.setState({ _mintStatus: false });

							MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
							this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
							console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

							public_mint_status = await contract.methods.public_mint_status().call();
							this.setState({ _public_mint_status: public_mint_status });
							console.log("public_mint_status :" + public_mint_status);

							specialPrice = await contract.methods.specialPrice().call();
							this.setState({ _specialPrice: specialPrice });
							console.log("specialPrice :" + specialPrice);

							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 1000 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;

								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 1000) {

									onlyLeft = 0;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = MAX_PER_WALLET - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}
							///................................///

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 1000 - totalSupplyNFT;

								if (special_mintAmount > onlyLeft) {
									special_mintAmount = onlyLeft;
								}

								valueOfNFTsSpecial = special_mintAmount * 0;
								wMintAmount = special_mintAmount;


								this.setState({ _nftSpecialMintingAmount: special_mintAmount });

								this.setState({ specialTotalValue: valueOfNFTsSpecial });
							} else {
								special_mintAmount = 1;

								if (totalSupplyNFT == 1000) {

									onlyLeft = 1000 - totalSupplyNFT;
									special_mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {
									special_mintAmount = 1;
									onlyLeft = MAX_PER_WALLET - publicSale;
									let onlyLeftSpecial = 20 - specialTotalSupply;
									if (onlyLeft < onlyLeftSpecial) {

										if (special_mintAmount > onlyLeft) {
											special_mintAmount = onlyLeft;
										}
									} else {
										if (special_mintAmount > onlyLeftSpecial) {
											special_mintAmount = onlyLeftSpecial;
										}
									}
									//mintAmount = onlyLeft;

									valueOfNFTsSpecial = special_mintAmount * this.state._specialPrice;
									wMintAmount = special_mintAmount;


									this.setState({ _nftSpecialMintingAmount: special_mintAmount });

									this.setState({ specialTotalValue: valueOfNFTsSpecial });
								}
							}


							//..........................................................................//

						} catch (err) {
							console.log("err: " + err);

						}
					}
				})();

				//.....................................................................//

				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}

		}

	}

	walletDisconnect = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		// disconnect wallet
		web3Modal.clearCachedProvider();
		window.location.reload();

	}

	whitelistMint = async event => {
		event.preventDefault();


		//	console.log('Whitelist Merkle Tree\n', merkleTree.toString());
		console.log("Root Hash: ", rootHash);
		console.log("Root HashHash: ", rootHashHash);

		// ***** ***** ***** ***** ***** ***** ***** ***** // 

		// CLIENT-SIDE: Use `msg.sender` address to query and API that returns the merkle proof
		// required to derive the root hash of the Merkle Tree

		// ✅ Positive verification of address
		//const claimingAddress = leafNodes[0];
		//console.log("Claiming Address:"+ claimingAddress);
		// ❌ Change this address to get a `false` verification
		const claimingAddress = keccak256(account);

		// `getHexProof` returns the neighbour leaf and all parent nodes hashes that will
		// be required to derive the Merkle Trees root hash.
		const hexProof = merkleTree.getHexProof(claimingAddress);
		console.log("HexProof:" + hexProof);

		// ✅ - ❌: Verify is claiming address is in the merkle tree or not.
		// This would be implemented in your Solidity Smart Contract
		console.log("Final result: " + merkleTree.verify(hexProof, claimingAddress, rootHash));
		FinalResult = merkleTree.verify(hexProof, claimingAddress, rootHash);
		this.setState({ _FinalResult: FinalResult });

		if (FinalResult) {
			try {
				this.setState({ statusError: false, statusLoading: true });
				await contract.methods.whitelistMint(hexProof, wMintAmount).send({ gasLimit: 285000, from: account, value: wMintAmount * 12000000000000000 });
				this.setState({ statusLoading: false, success: true });
				console.log("Mint Amount :" + this.state.wMintAmount);

			} catch (err) {
				this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
				console.log(err);
			}
		} else {
			wlMsg = "Not Eligible for WL"
			this.setState({ _wlMsg: wlMsg });
		}

	}

	render() {

		return (

			<div class="allWrap">
				<div class="light">

					<div class="cont">

						<div class="headers">

							<div class="h1">
								<div class="connect">
									<div ><Link activeClass="" id="fontSize" to="about" spy={true} smooth={true} duration={550}>ABOUT</Link></div>
									<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550}>MINT</Link></div>
									<div ><Link activeClass="" id="fontSize" to="rm" spy={true} smooth={true} duration={550}>ROADMAP</Link></div>
									<div ><Link activeClass="" id="fontSize" to="team" spy={true} smooth={true} duration={550}>TEAM</Link></div>
									<div ><Link activeClass="" id="fontSize" to="faq" spy={true} smooth={true} duration={550}>FAQ</Link></div>

								</div>

								<div class="right">
									<div class="connect2Mobile">
										{this.state.walletAddress === '' ?
											(<form onSubmit={this.walletConnect}>
												<button class="wallet2" >Wallet Connect</button>
											</form>) : (<form onSubmit={this.walletDisconnect}><button class="wallet2" >
												{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}

									</div>
									<div class="icons">
										<div class="discord"><img class="osPic" onClick={opensea} src={os} /></div>
										<div class="discord">
											<SocialIcon class="twitter" url="https://discord.gg/F6pmt2HNU3" target="_blank" network="discord" bgColor="black" style={{ height: 30, width: 30 }} />
										</div>
										<div class="discord">
											<SocialIcon class="twitter" url="https://twitter.com/Pooh_NFTS" target="_blank" network="twitter" bgColor="black" style={{ height: 30, width: 30 }} />
										</div>

										<div class="discord2"><img class="osPic" onClick={opensea} src={os} /></div>

										<div class="discord2">
											<SocialIcon class="twitter" url="https://discord.gg/F6pmt2HNU3#" target="_blank" network="discord" bgColor="black" style={{ height: 45, width: 45 }} />
										</div>

										<div class="discord2">
											<SocialIcon class="twitter" url="https://twitter.com/Pooh_NFTS" target="_blank" network="twitter" bgColor="black" style={{ height: 45, width: 45 }} />
										</div>

										<div class="discord3"><img class="osPic" onClick={opensea} src={os} /></div>

										<div class="discord3">
											<SocialIcon class="twitter" url="https://discord.gg/F6pmt2HNU3" target="_blank" network="discord" bgColor="black" style={{ height: 60, width: 60 }} />
										</div>

										<div class="discord3">
											<SocialIcon class="twitter" url="https://twitter.com/Pooh_NFTS" target="_blank" network="twitter" bgColor="black" style={{ height: 60, width: 60 }} />
										</div>

										<div class="discord4"><img class="osPic" onClick={opensea} src={os} /></div>

										<div class="discord4">
											<SocialIcon class="twitter" url="https://discord.gg/F6pmt2HNU3" target="_blank" network="discord" bgColor="black" style={{ height: 75, width: 75 }} />
										</div>

										<div class="discord4">
											<SocialIcon class="twitter" url="https://twitter.com/Pooh_NFTS" target="_blank" network="twitter" bgColor="black" style={{ height: 75, width: 75 }} />
										</div>
									</div>
									<div class="connect2">
										{this.state.walletAddress === '' ?
											(<form onSubmit={this.walletConnect}>
												<button class="wallet2" >WALLET CONNECT</button>
											</form>) : (<form onSubmit={this.walletDisconnect}><button class="wallet2" >
												{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}

									</div>

									<div class="iconsMob">
										<div class="discord"><img class="osPic" onClick={opensea} src={os} /></div>
										<div class="discord"><img class="osPic" src={discord} /></div>


										<div class="discord">
											<SocialIcon class="twitter" url="#" target="_blank" network="twitter" bgColor="black" style={{ height: 30, width: 30 }} />
										</div>


									</div>
								</div>

							</div>

							<div class="connectMobile">
								<div ><Link activeClass="" id="fontSize" to="about" spy={true} smooth={true} duration={550}>ABOUT</Link></div>
								<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550}>MINT</Link></div>
								<div ><Link activeClass="" id="fontSize" to="rm" spy={true} smooth={true} duration={550}>ROADMAP</Link></div>
								<div ><Link activeClass="" id="fontSize" to="team" spy={true} smooth={true} duration={550}>TEAM</Link></div>
								<div ><Link activeClass="" id="fontSize" to="faq" spy={true} smooth={true} duration={550}>FAQ</Link></div>

							</div>
						</div>
						<div class="introduction">

							<div class="in2">
								<div class="intro">
									<img src={logo} />
								</div>

							</div>

						</div>

					</div>

					<Carousel />

					<Element name="about">
						<Story />
					</Element>

					<Element name="mint">
						<div class="boxWrap2Mint">
							<div class="mintH">MINTING</div >

							<div class="mintDiv">
								<div class="pDiv"><img class="parrot" src={nft} /></div>
								<div class="mintCon">
									<div class="totalSupply">{this.state._normalNFTSupply}/980</div>
									<div class="price"><div>Mint Price {this.state._public_sale_cost / 1000000000000000000} ETH</div></div>

									<div class="minting_count_button">
										<div class="center">
											<form onSubmit={this.onSubmitMinus}>
												<button class="btnfos-0-2" type="submit">-</button>
											</form>
										</div>

										<div>
											<div class="nftminter2"><span class="nftamount">{this.state.nftMintingAmount}</span></div>
										</div>

										<div class="center">
											<form onSubmit={this.onSubmitPlus}>
												<button class="btnfos-0-2-2" type="submit">+</button>
											</form>
										</div>
									</div>

									{this.state.walletAddress === '' ?

										(<div class="mintbuttondiv">
											<form onSubmit={this.walletConnect}>
												<button class="btnfos-0-3">Connect</button>
											</form></div>) :
										(<div class="mintbuttondiv">
											{this.state._mintStatus === true ? (
												<div>

													<form onSubmit={this.onSubmit2}>
														<button class="btnfos-0-3" type="submit">
															Mint Here</button>
													</form>

												</div>
											) : (<form onSubmit={this.onSubmit2}>
												<button class="btnfos-0-3" type="submit">
													Mint Here</button>
											</form>)}
										</div>)}
									<div>

										{this.state.statusErrorA ? (
											<div class="errorMessage">
												<div >Sorry, something went wrong please try again later</div>
											</div>)
											: null}

										{this.state.statusLoadingA ? (
											<div class="loadingContainer">
												<div>
													<div class="loadingText">Minting your Pooh Bear</div>
													<div class="loadTextSub">(this can take up to 30 seconds)</div>
												</div>
											</div>)
											: null}

										{this.state.successA ? (

											<div><div class="successfully">MINTING SUCCESSFUL!</div>
												<div class="link"></div></div>)
											: null}

									</div>

								</div>
							</div>

							<div class="mintDiv">
								<div class="pDiv"><img class="parrot" src={picSpecial} /></div>
								<div class="mintCon">
									<div class="totalSupply">{this.state._specialTotalSupply}/20</div>
									<div class="price"><div>Mint Price {this.state._specialPrice / 1000000000000000000} ETH</div></div>

									<div class="minting_count_button">
										<div class="center">
											<form onSubmit={this.onSubmitMinusSpecial}>
												<button class="btnfos-0-2" type="submit">-</button>
											</form>
										</div>

										<div>
											<div class="nftminter2"><span class="nftamount">{this.state._nftSpecialMintingAmount}</span></div>
										</div>

										<div class="center">
											<form onSubmit={this.onSubmitPlusSpecial}>
												<button class="btnfos-0-2-2" type="submit">+</button>
											</form>
										</div>
									</div>

									{this.state.walletAddress === '' ?

										(<div class="mintbuttondiv">
											<form onSubmit={this.walletConnect}>
												<button class="btnfos-0-3">Connect</button>
											</form></div>) :
										(<div class="mintbuttondiv">
											{this.state._mintStatus === true ? (
												<div>

													<form onSubmit={this.cf}>
														<button class="btnfos-0-3" type="submit">
															Mint Here</button>
													</form>

												</div>
											) : (<form onSubmit={this.specialMint}>
												<button class="btnfos-0-3" type="submit">
													Mint Here</button>
											</form>)}
										</div>)}
									<div>

										{this.state.statusErrorB ? (
											<div class="errorMessage">
												<div >Sorry, something went wrong please try again later</div>
											</div>)
											: null}

										{this.state.statusLoadingB ? (
											<div class="loadingContainer">
												<div>
													<div class="loadingText">Minting your Ukranian Pooh Bear</div>
													<div class="loadTextSub">(this can take up to 30 seconds)</div>
												</div>
											</div>)
											: null}

										{this.state.successB ? (

											<div><div class="successfully">MINTING SUCCESSFUL!</div>
												<div class="link"></div></div>)
											: null}

									</div>

								</div>
							</div>


						</div>
					</Element>

					<Element name="rm">
						<Roadmap />
					</Element>
					<Element name="team">
						<Team />
					</Element>
					<Element name="faq">
						<FAQ />
					</Element>
					<Footer />
				</div>
			</div >)
	}
}

export default App;
