import React, { Component } from 'react';
import honeyComb from '../assets/honeyComb.png'; 

class FAQ extends Component {


  render() {
    return (

      <div id="faq" class="faqbg">

        <div class="accordin reveal">

        <div class="storyH">FAQ</div>

          <details >
            <summary>What are Pooh NFTS?</summary>
            <div class="faq__content">
              <p>Pooh NFTS are nonfungible token art with a purpose discussed in the roadmap and we donate a part to nonprofit bee-saving charities and charities for Ukraine (with the Ukrainian Pooh NFTS).</p>
            </div>
          </details>
          <details>
            <summary>Why should you buy Pooh NFTS?</summary>
            <div class="faq__content">
              <p>You should buy Pooh NFTS as an investment for your future (see roadmap) and to donate for a good cause.</p>

            </div>
          </details>
          <details>
            <summary>What can I do with the NFT?</summary>
            <div class="faq__content">
              <p>You can keep it and sell it for more once it goes up in value or hold it for our amazing perks.</p>

            </div>
          </details>

          <details>
            <summary>Is it safe?</summary>
            <div class="faq__content">
              <p>Yes, it's completely safe and we ensure you that we will give you the best and smoothest experience.</p>

            </div>
          </details>

          <details>
            <summary>What charities are we donating to?</summary>
            <div class="faq__content">
              <p>For the normal nfts we will be donating to Project Apis M and for the Ukrainian nfts we will be donating to various charities.</p>

            </div>
          </details>

          <details>
            <summary>What are our real names?</summary>
            <div class="faq__content">
              <p>Misha and Michel.</p>

            </div>
          </details>

        </div>
        <img class="honeyComb" src={honeyComb}/>
      </div>


    )
  }
}

export default FAQ;

