import React, { Component } from 'react';
import '../App.css';
import founder from '../assets/founder.jpg';
import artist from '../assets/Ian.jpg';

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 15;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);


class Team extends Component {

  async componentDidMount() {

  }

  render() {
    return (
      <div class="boxWrap2Team">

        <div class="storyH">TEAM</div>

        <div class="teamSection">
          <div class="team1">
            <div class="manDiv"><img class="man" src={founder} /></div>
            <div class="tAndName">
              <div class="memName">Judar Shekel</div>

            </div>
            <div class="memNamePosition">The CEO, CFO CTO, mastermind genius, business guru</div>
          </div>

          <div class="team1">
            <div class="manDiv"><img class="man" src={artist} /></div>
            <div class="tAndName">
            <div class="memName">Uncle Ian</div>

            </div>
            <div class="memNamePosition"> I never lose</div>

          </div>

        </div>
      </div>
    )
  }
}

export default Team;