import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import '../firefly.css';
import '../drippy.css';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Story extends Component {

    render() {
        return (

            <div class="boxWrap2Story">
               <div class="firefly"></div>
						<div class="firefly"></div>
						<div class="firefly"></div>
						<div class="firefly"></div>
						<div class="firefly"></div>
						<div class="firefly"></div>
						<div class="firefly"></div>
						<div class="firefly"></div>
						<div class="firefly"></div>
						<div class="firefly"></div>
						<div class="firefly"></div>
						<div class="firefly"></div>
						<div class="firefly"></div>
						<div class="firefly"></div>
						<div class="firefly"></div>
                <div class="storyH">ABOUT</div>

                <div class="about">
                    <div class="storyCon">
                        <p class="storyCon2">We are 2 friends that had a good idea and brought it to life. We want our and your children to live a good life by donating to better the world and by giving you amazing perks.</p>
                    </div>
                </div>

            </div>
        )
    }
}

export default Story;

