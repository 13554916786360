import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import dripping from '../assets/dripping.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Footer extends Component {




    render() {
        return (

            <div class="boxWrap2Footer">

                <img class="dripCover" src={dripping}/>
                <div class="footer">
                <div class="logoF">POOH NFTS</div>

                </div>
                <div class="copyright">Copyright © 2022 Pooh NFTs. All Rights Reserved</div>

            </div>
        )
    }
}

export default Footer;

