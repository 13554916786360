import React, { Component } from 'react';
import '../carousel.css';
import d1 from '../assets/1.jpg';
import d2 from '../assets/2.jpg';
import d3 from '../assets/3.jpg';
import d4 from '../assets/4.jpg';
import d5 from '../assets/5.jpg';
import d6 from '../assets/6.jpg';
import d7 from '../assets/7.jpg';
import d8 from '../assets/8.jpg';
import d9 from '../assets/9.jpg';
import d10 from '../assets/10.jpg';
import d11 from '../assets/11.jpg';
import d12 from '../assets/12.jpg';
import d13 from '../assets/13.jpg';
import d14 from '../assets/14.jpg';
import d15 from '../assets/15.jpg';
import '../drippy.css' 

class Carousel extends Component { 

    render() {
        return (

            <div class="boxWrapC">
					<div class="drip-wrapper">
                    <div class="drip-top"></div>
                    <div class="drip"></div>
                    <div class="drip"></div>
                    <div class="drip"></div>
                    <div class="drip"></div>
                    <div class="drip"></div>
                    <div class="cdrip"></div>
                    <div class="drip"></div>
                    <div class="drip"></div>
                    <div class="drip"></div>
                    <div class="drip"></div>
                    <div class="drip-bottom"></div>
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                        <defs>
                            <filter id="goo">
                                <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
                                <feBlend in="SourceGraphic" in2="goo" />
                            </filter>
                        </defs>
                    </svg>
                </div>
                <div class="slider">
                    <div class="slide-track2">
                        <div class="slide">
                            <img class="carouselIMG" src={d1} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d2} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d3} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d4} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d5} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d6} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d7} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d8} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d9} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d10} alt="d5" />
                        </div>
                    
    
                    </div>
                </div>

                <div class="slider2">
                    <div class="slide-track3">
                        <div class="slide">
                            <img class="carouselIMG" src={d11} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d12} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d13} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d14} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d15} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d11} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d12} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d13} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d14} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d15} alt="d5" />
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Carousel;        